@import "../../../styles/base/abstracts";

.hbs-big-grid-list {
  display: flex;
  flex-flow: row wrap;
  row-gap: $grid-padding-mobile * 2;

  @include mq($bp-tablet) {
    @include grid-parent($offset: true);
    row-gap: $grid-padding * 2;
  }
}

.hbs-component-header--large .hbs-component-header__inner {
  margin-bottom: $spacing-lg;
  padding-bottom: $spacing-sm;
}

// List
// -------------------------------------------

.hbs-big-grid-list__content {
  @include mq($bp-tablet) {
    @include padding(x $grid-padding);
  }
  // Desktop Large
  @include mq($bp-desktop-lg) {
    .hbs-global-align-full & {
      width: $col-12;
    }
  }
}

.hbs-big-grid-list__intro {
  border-bottom: $border-dark;
  padding-bottom: $spacing-md;
  margin-bottom: 0;

  @include mq($bp-tablet) {
    margin-bottom: $spacing-lg;
    padding-bottom: $spacing-lg;
  }
}

.hbs-big-grid-list__title,
.hbs-big-grid-list__subtitle {
  @include mq($bp-desktop-lg) {
    .hbs-global-align-full & {
      width: $col-12;
    }
  }
}

.hbs-big-grid-list__subtitle {
  color: $c-text-light;
  font-family: $ff-sans;
  font-weight: $fw-reg;
}

.hbs-component--component-header + .hbs-big-grid-list__list {
  margin-top: $spacing-md;

  @include mq($bp-tablet) {
    margin-top: $grid-padding * 2;
  }
}

.hbs-component--component-header ~ .hbs-big-grid-list--has-header {
  .hbs-big-grid-list__intro {
    border-bottom: none;
    margin-bottom: 0;
  }

  .hbs-big-grid-list__item:first-child {
    .hbs-big-grid-list__item-title {
      padding-top: 0;
    }
  }
}

.hbs-big-grid-list__list {
  @include list-reset;
  display: grid;
  gap: $grid-padding-mobile * 2;
  overflow: hidden;
  margin-left: -5px;
  margin-right: -5px;

  .hbs-big-grid-list__item {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    margin-bottom: -5px;
  }

  .hbs-big-grid-list--has-header & {
    .hbs-big-grid-list__item:first-child {
      .hbs-big-grid-list__item-title {
        border-top: none;
      }
    }
  }

  .hbs-big-grid-list__item-title {
    @include body-title;
    border-top: $border-light;
    padding-top: $grid-padding-mobile * 2;
    padding-bottom: $spacing-xxs;
  }

  .hbs-big-grid-list__item-subtitle {
    @include baseline-text;
    color: $c-text-light;
  }

  // Tablet
  @include mq($bp-tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: $grid-padding * 2;

    .hbs-big-grid-list__item {
      padding-right: $grid-padding * 2;
    }

    .hbs-big-grid-list__item-title {
      border-top: none;
      padding-top: 0;
      padding-bottom: $spacing-xxs;
    }
  }

  // Desktop
  @include mq($bp-desktop) {
    .hbs-big-grid-list--has-media--3up & {
      grid-template-columns: repeat(3, 1fr);
    }

    .hbs-big-grid-list__item {
      box-shadow: 1px 0 $c-border-light;
    }
  }
}

// CTA
// -------------------------------------------
.hbs-big-grid-list__cta {
  width: 100%;
  margin-top: $spacing-lg;

  .hbs-big-grid-list__cta-link {
    @include padding($spacing-lg x $spacing-xxs);
    border-top: $border-solid;
    display: flex;
  }

  @include mq($bp-tablet) {
    width: 100%;
    margin-top: $grid-padding * 2;
  }
}

// With Media
// -------------------------------------------
.hbs-big-grid-list--has-media {
  .hbs-big-grid-list__media {
    display: none;

    @include mq($bp-tablet) {
      display: block;
    }
  }

  @include mq($bp-tablet) {
    flex-direction: row;

    &.hbs-big-grid-list--aligned-right {
      flex-direction: row-reverse;
    }

    .hbs-big-grid-list__content {
      width: $col-12;
    }

    .hbs-big-grid-list__media {
      @include padding(x $grid-padding);
      width: $col-4;
    }
  }

  @include mq($bp-desktop) {
    .hbs-big-grid-list__media {
      margin-right: auto;
      width: $col-3;
    }

    &.hbs-big-grid-list--aligned-right {
      .hbs-big-grid-list__media {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}
